<template>
  <v-dialog v-model="value" width="600" persistent>
    <v-form v-model="valid" ref="editGroupForm">
      <v-card class="w-100 rounded-lg">
        <v-card-title>{{ $t('companies.groups.edit') }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.name"
            :label="$t('companies.groups.editName')"
            outlined
            dense
            counter
            class="mb-2"
            :rules="nameRules"
          >
          </v-text-field>

          <v-textarea
            v-model="form.description"
            :label="$t('companies.groups.editDescription')"
            outlined
            counter
            class="mb-2"
            :rules="descriptionRules"
          >
          </v-textarea>

          <AutoComplete
            v-model="form.companies"
            :selected="group.companies"
            :items="companies"
            :label="$t('companies.groups.editCompanies')"
            item-text="name"
            item-value="id"
            multiple
            outlined
            dense
            class="mb-2"
            :rules="requiredRules"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                class="mt-1"
                small
                color="primary"
              >
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption mt-1"
              >
                  (+{{ form.companies.length -1 }} {{ $t('common.others')}})
              </span>
            </template>
          </AutoComplete>

        </v-card-text>
        <v-card-actions class="d-flex justify-end">

          <v-btn
            outlined
            :disabled="loading"
            @click="close()"
            class="ml-2"
          >
            {{ $t('common.cancel') }}
          </v-btn>

          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="save()"
          >
            {{ $t('companies.groups.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Service from '@/services'
import AutoComplete from '@/components/forms/AutoComplete.vue'

export default {
  name: 'EditGroupDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  components: {
    AutoComplete
  },
  data () {
    return {
      valid: true,
      loading: false,
      companies: [],
      nameRules: [
        (v) => !!v || this.$t('validation.fieldRequired'),
        (v) => (v && v.length <= 50) || this.$t('validation.groupNameMaximumLength')
      ],
      descriptionRules: [
        (v) => !!v || this.$t('validation.fieldRequired'),
        (v) => (v && v.length <= 250) || this.$t('validation.groupDescriptionMaximumLength')
      ],
      requiredRules: [
        (v) => (v && v.length > 0) || this.$t('validation.fieldRequired')
      ],
      form: {}
    }
  },
  async created () {
    await this.getCompanies()
  },
  methods: {
    open () {
      this.form.id = this.group.id
      this.form.name = this.group.name
      this.form.description = this.group.description
      this.form.companies = this.group.companies.map(x => x.id)
    },
    async getCompanies () {
      try {
        const { data } = await Service.get(
          '/Company/GetCompanyNames'
        )
        this.companies = data
      } catch (e) {
      }
    },
    close () {
      this.$emit('close')
      this.$refs.editGroupForm.resetValidation()
    },
    async save () {
      this.$refs.editGroupForm.validate()

      if (!this.valid) {
        return
      }

      this.loading = true
      try {
        const model = {
          groupId: this.form.id,
          companies: this.form.companies.map(function (x) { return x?.id ?? x }),
          name: this.form.name,
          description: this.form.description
        }

        await Service.put('CompanyGroup/UpdateGroup', model)
        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation')
        )
        this.$emit('group-edited')
        this.$refs.editGroupForm.resetValidation()
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    value: {
      handler (newVal) {
        if (newVal) { this.open() }
      }
    }
  }
}
</script>
