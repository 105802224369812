<template>
  <div>
    <v-card class="pa-0 pb-10" tile>
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('companies.title') }}
        </h4>
        <v-spacer />
        <v-btn color="primary" class="ml-auto" @click="openAddGroupDialog()" depressed>
          {{ $t('companies.groups.add') }}
        </v-btn>
      </v-card-title>
      <div class="background px-6 my-4">
        <v-tabs v-model="activeTab" :height="60" background-color="background" show-arrows hide-slider center-active>
          <v-tab>{{ $t('companies.tabs.companiesList') }}</v-tab>
          <v-tab>{{ $t('companies.tabs.groups') }}</v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card class="py-5">
            <CompaniesTable ref="companiesTable" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="py-5">
            <GroupsTable ref="groupsTable" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <AddGroupDialog
      v-model="addGroupDialog"
      @group-added="groupAdded()"
      @close="closeAddGroupDialog()"
    />
  </div>
</template>

<script>
import CompaniesTable from '@/views/Companies/Market/tables/CompaniesTable'
import GroupsTable from '@/views/Companies/Market/tables/GroupsTable'
import AddGroupDialog from '@/views/Companies/Market/dialogs/AddGroupDialog'

export default {
  name: 'Companies',
  components: {
    AddGroupDialog,
    GroupsTable,
    CompaniesTable
  },
  data () {
    return {
      activeTab: null,
      addGroupDialog: false
    }
  },
  methods: {
    openAddGroupDialog () {
      this.addGroupDialog = true
    },
    closeAddGroupDialog () {
      this.addGroupDialog = false
    },
    async groupAdded () {
      if (this.$refs.groupsTable) { await this.$refs.groupsTable.updateData() }
      this.addGroupDialog = false
    }
  }
}
</script>
<style scoped>
.v-tab::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

/* .v-tab--active, */
.v-tab:hover {
  /* font-weight: bold; */
  color: var(--v-primary-base) !important;
  text-shadow: 0 0 .7px var(--v-primary-base), 0 0 .7px var(--v-primary-base);
}

.v-tab--active:hover,
.v-tab--active {
  font-weight: bold;
  color: var(--v-primary-base) !important;
  text-shadow: none;
}

.v-tab {
  border-left: solid 1px var(--v-separator-base);
  margin: 10px 0;
}

.v-tab:last-child {
  border-right: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
</style>
