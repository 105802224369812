<template>
  <div>
    <v-card class="pa-5">
      <div class="d-flex align-center mb-6">
        <h2 class="text-subtitle-1 font-weight-bold mr-4">
          {{ $t("companies.companiesList") }}
        </h2>
        <div>
          <SearchInput v-model="searchInputValue" />
        </div>
         <v-row no-gutters>
          <v-col cols="4" md="4">
             <v-select
            v-model="salesmanFilter"
            :items="salesUsers"
            item-text="name"
            clearable
            item-value="id"
          ></v-select>
          </v-col>
        </v-row>
      </div>
      <data-table
        ref="dataTable"
        :headers="headers"
        show-expand
        single-expand
        serverPagination
        endpoint="CustomerService/GetCurrentCompanies"
        @item-expanded="loadCompanyUsers"
        :additionalParams="{ salesman: salesmanFilter}"
        :search="searchInputValue"
      >
        <template v-slot:[`item.documentsState`]="{ item }">
          <v-chip
            v-if="documentsNeedAction(item.documentsState)"
            class="ma-2"
            small
            label
            color="stateChange"
          >
            {{ getDocumentState(item.documentsState) }}
          </v-chip>
          <v-chip v-else class="ma-2" small label color="active">
            {{ getDocumentState(item.documentsState) }}
          </v-chip>
        </template>

        <template v-slot:[`item.averageRating`]="{ item }">
          <v-rating
            background-color="amber accent-4"
            color="amber accent-4"
            length="5"
            readonly
            size="16"
            :value="item.reviews.averageRating"
          />
          <b>
            {{ item.reviews.averageRating }}
          </b>
          ({{ item.reviews.numberOfReviews }})
        </template>

        <template v-slot:[`item.dataChanges`]="{ item }">
          <v-chip
            v-if="item.dataChanges"
            class="ma-2"
            small
            label
            color="stateChange"
          >
            {{ $t("companies.update") }}
          </v-chip>
          <v-chip v-else class="ma-2" small label color="active">
            {{ $t("companies.upToDate") }}
          </v-chip>
        </template>

        <template v-slot:[`item.acceptedBy`]="{ item }">
          <div v-if="item.acceptedBy">
            {{ item.acceptedBy.name }} {{ item.acceptedBy.surname }}
          </div>
        </template>

        <template v-slot:[`item.salesman`]="{ item }">
          <div v-if="item.salesman">
            {{ item.salesman.name }} {{ item.salesman.surname }}
          </div>
        </template>

        <template v-slot:[`item.dateOfAcceptation`]="{ item }">
          {{ item.dateOfAcceptation | dateFormat }}
        </template>

        <template v-slot:[`item.isIntegrationApiEnabled`]="{ item }">
          <v-checkbox
              class="d-flex align-end"
              v-model="item.isIntegrationApiEnabled"
              hide-details
              outlined
              :disabled="!$store.getters.isAdmin"
              @change="integrationApiEnabledUpdated(item.id, item.isIntegrationApiEnabled)"
            >
            </v-checkbox>
        </template>
        <template v-slot:[`item.isReportTabEnabled`]="{ item }">
          <v-checkbox
              class="d-flex align-end"
              v-model="item.isReportTabEnabled"
              hide-details
              outlined
              :disabled="!$store.getters.isAdmin"
              @change="reportTabEnabledUpdated(item.id, item.isReportTabEnabled)"
            >
            </v-checkbox>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip class="ma-2" small label color="active">
            {{ getUserStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link v-if="item.dataChanges && $permission('CompanyUpdateRequestsManagement')" @click="verify(item)">
                <v-list-item-title>
                  {{ $t("companies.verifyProfile") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="handleCompanyClick(item)">
                <v-list-item-title>
                  {{ $t("companies.profile") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td class="pa-6" :colspan="headers.length">
            <h4>{{ $t("companies.employees") }}</h4>
            <v-data-table
              :headers="detailsHeaders"
              :items="companyUsers"
              :loading="companyUsersLoading"
              hide-default-footer
            >
              <template v-slot:[`item.name`]="{ item }">
                <div
                  v-if="item.name &&
                  item.name.length > 0 &&
                  item.surname &&
                  item.surname.length > 0"
                  class="d-flex align-center"
                >
                  <StatusAvatar :userId="item.id" showStatus class="mr-2"></StatusAvatar>
                  <span>{{ item.name }} {{ item.surname }}</span>
                </div>
              </template>
              <template v-slot:[`item.role`]="{ item }">
                {{ getCompanyUserRole(item.role) }}
              </template>
              <template v-slot:item.status="{ item }">
                  <v-chip class="ma-2" small label color="active">
                {{ getUserStatus(item.status) }}
              </v-chip>
        </template>
            </v-data-table>
          </td>
        </template>
      </data-table>
    </v-card>
    <data-changes
      :dialog="verifyDialog"
      :company="selectedCompany"
      :loading="loadingDialog"
      @cancel="verifyDialogCancel"
      @rejectChanges="verifyDialogReject"
      @approveChanges="verifyDialogApprove"
    />
  </div>
</template>

<script>
import { getEnumDescription } from '@/utils/helpers'
import { mapGetters } from 'vuex'
import Service from '@/services'
import SearchInput from '@/components/search/SearchInput.vue'
import DataChanges from './dialogs/DataChanges.vue'
import StatusAvatar from '@/components/StatusAvatar.vue'
import * as documentStateTypes from '@/types/document-state-type.js'

export default {
  name: 'Companies',
  components: {
    SearchInput,
    DataChanges,
    StatusAvatar
  },
  computed: {
    ...mapGetters({
      enums: 'enums',
      getUsers: 'users/users'
    }),
    userStatus () {
      return this.$store.getters.enums.userStatus
    }
  },
  async created () {
    await this.fetchData()
  },
  data () {
    return {
      loading: true,
      companies: [],
      dataTableOptions: {
        page: 1,
        itemsPerPage: 10
      },
      companyUsers: [],
      salesmanFilter: null,
      salesUsers: null,
      companyUsersLoading: true,
      searchInputValue: '',
      loadingDialog: false,
      verifyDialog: false,
      selectedCompany: {},
      documentStateTypes,
      headers: [
        {
          text: this.$t('companies.companyName'),
          value: 'name'
        },
        {
          text: this.$t('companies.companyId'),
          value: 'displayId'
        },
        {
          text: this.$t('companies.email'),
          value: 'ownerEmail'
        },
        {
          text: this.$t('companies.vatNumber'),
          value: 'vatNumber'
        },
        {
          text: this.$t('companies.localisation'),
          value: 'localization'
        },
        {
          text: this.$t('companies.documents'),
          align: 'center',
          value: 'documentsState'
        },
        {
          text: this.$t('companies.reviews'),
          value: 'averageRating'
        },
        {
          text: this.$t('companies.data'),
          align: 'center',
          value: 'dataChanges'
        },
        {
          text: this.$t('companies.salesman'),
          align: 'center',
          value: 'salesman'
        },
        {
          text: this.$t('companies.acceptedBy'),
          value: 'acceptedBy'
        },
        {
          text: this.$t('companies.dateOfAcceptation'),
          value: 'dateOfAcceptation'
        },
        {
          text: this.$t('companies.integrationApi'),
          value: 'isIntegrationApiEnabled'
        },
        {
          text: this.$t('companies.reportsTab'),
          value: 'isReportTabEnabled'
        },
        {
          text: this.$t('companies.state'),
          value: 'status'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('companies.employee.fullName'),
          value: 'name',
          width: '30%'
        },
        {
          text: this.$t('companies.employee.displayId'),
          value: 'displayId',
          width: '15%'
        },
        {
          text: this.$t('companiesDetails.email'),
          value: 'email',
          width: '15%'
        },
        {
          text: this.$t('companies.employee.phone'),
          value: 'phone',
          width: '15%'
        },
        {
          text: this.$t('companies.employee.role'),
          value: 'role',
          width: '15%'
        },
        {
          text: this.$t('users.state'),
          value: 'status',
          align: 'start',
          width: '120'
        },
        {
          text: '',
          value: '',
          width: '25%',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async fetchData () {
      try {
        const { data: salesUsersToAssign } = await Service.get('User/GetSalesUsers')
        this.salesUsers = salesUsersToAssign
        console.log(this.salesUsers)
      } catch (e) {
      } finally {
      }
    },
    async integrationApiEnabledUpdated (companyId, value) {
      await Service.post(
        'Company/SetIntegrationApi', {
          companyId: companyId,
          isEnabled: value
        }
      )
    },
    async reportTabEnabledUpdated (companyId, value) {
      await Service.post(
        'Company/SetReportTab', {
          companyId: companyId,
          isEnabled: value
        }
      )
    },
    getUserStatus (status) {
      return getEnumDescription(this.userStatus, status)
    },
    async loadCompanies () {
      await this.$refs.dataTable.getData()
    },
    async loadCompanyUsers ({ item }) {
      this.companyUsers = []
      this.companyUsersLoading = true

      const params = {
        companyId: item.id
      }

      const response = await Service.get(
        'CustomerService/GetCompanyUsers',
        params
      )
      this.companyUsers = response.data
      this.companyUsersLoading = false
    },
    getCompanyUserRole (role) {
      return getEnumDescription(this.enums.roles, role)
    },
    getDocumentState (documentState) {
      return getEnumDescription(this.enums.documentStateTypes, documentState)
    },
    async verify (company) {
      const response = await Service.get('/Company/GetCompanyDetails', {
        companyId: company.id
      })
      this.selectedCompany = response.data
      this.verifyDialog = true
    },
    verifyDialogCancel () {
      this.verifyDialog = false
    },
    handleCompanyClick (value) {
      const routeData = this.$router.resolve({ name: 'CompaniesDetailsCustomerService', params: { id: value.id } })
      window.open(routeData.href, '_blank')
    },
    async verifyDialogReject () {
      this.loadingDialog = true

      await Service.post('/CompanyManagement/RejectCompanyUpdateRequest', {
        requestId: this.selectedCompany.companyChanges.id
      })
      this.verifyDialog = false
      await this.$store.dispatch(
        'setSuccessNotification',
        this.$t('companies.rejectDataChanges')
      )

      await this.loadCompanies()
      this.loadingDialog = false
    },
    async verifyDialogApprove () {
      this.loadingDialog = true

      await Service.post('/CompanyManagement/AcceptCompanyUpdateRequest', {
        requestId: this.selectedCompany.companyChanges.id
      })

      this.verifyDialog = false
      await this.$store.dispatch(
        'setSuccessNotification',
        this.$t('companies.approveDataChanges')
      )

      await this.loadCompanies()
      this.loadingDialog = false
    },
    documentsNeedAction (documentsState) {
      return documentsState === documentStateTypes.OUTDATED ||
             documentsState === documentStateTypes.UNVERIFIED
    }
  }
}
</script>

<style scoped>
.stateChange {
  color: var(--v-stateChange);
}
.active {
  color: var(--v-primary);
}
</style>
