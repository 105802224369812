<template>
  <v-dialog v-model="value" width="600" persistent>
    <v-card class="w-100 rounded-lg">
      <v-card-title>{{ $t('companies.filter') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="5">
            <CountryFlagSelect
              v-model="localFilters.countries"
              class="mb-2"
              multiple
              hide-details
              :label="$t('companiesDetails.country')"
            />
          </v-col>
        </v-row>

        <v-select
            v-model="localFilters.groups"
            :items="groups"
            :label="$t('companies.groups.title')"
            item-text="name"
            item-value="id"
            multiple
            outlined
            dense
            hide-details
            class="mb-2"
        >
        </v-select>

        <v-text-field
            v-model="localFilters.city"
            :label="$t('companies.city')"
            dense
            outlined
            hide-details
            class="mb-2"
        >
        </v-text-field>

        <v-select
            v-model="localFilters.cooperationStatus"
            :items="companyCooperationStatuses"
            :label="$t('companies.cooperationStatus')"
            item-text="description"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-2"
        >
        </v-select>
      <v-card-actions class="d-flex px-0">
        <v-btn outlined @click="close()" >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="filter()" class="ml-auto" depressed>
          {{ $t('companies.filter') }}
        </v-btn>
      </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import CountryFlagSelect from '@/components/CountryFlagSelect'

export default {
  name: 'FilterDialog',
  components: {
    CountryFlagSelect
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      groups: [],
      countries: []
    }
  },
  computed: {
    ...mapGetters({
      enums: 'enums'
    }),
    companyCooperationStatuses () {
      const { companyCooperationStatus } = this.enums
      return companyCooperationStatus
    },
    localFilters: {
      get () {
        return this.filters
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  async created () {
    await this.getGroups()
  },
  methods: {
    async getGroups () {
      try {
        const { data } = await Service.get(
          '/Company/GetGroupsForSearch'
        )
        this.groups = data
      } catch (e) {}
    },
    close () {
      this.$emit('close')
    },
    filter () {
      this.$emit('filter', this.filters)
    }
  }

}
</script>
