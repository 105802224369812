<template>
  <div>
    <v-row class="px-6 mb-4">
      <v-col cols="2">
        <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
      </v-col>
    </v-row>
    <data-table
      ref="table"
      :headers="headers"
      hide-default-footer
      server-pagination
      show-expand
      single-expand
      endpoint="CompanyGroup/GetAll"
      :search="searchInputValue"
    >
      <template v-slot:[`item.companiesCount`]="{ item }">
        <span>
          {{ item.companiesCount }}
        </span>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <div v-if="item.createdBy">
          {{ item.createdBy.name }}
          {{ item.createdBy.surname }}
        </div>
      </template>

      <template v-slot:[`item.dateOfCreation`]="{ item }">
        {{ item.dateOfCreation | dateFormat() }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="openEditGroupDialog(item)">
              <v-list-item-title>{{
                  $t("common.edit")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="openRemoveGroupDialog(item)">
              <v-list-item-title>{{
                  $t("common.remove")
                }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-6" :colspan="headers.length">
          <h4>{{ $t("companies.detailsHeaders.title") }}</h4>
          <v-data-table
            :headers="detailsHeaders"
            :items="item.companies"
            hide-default-footer
            class="pointer"
            @click:row="goToCompanyProfile"
          >
          </v-data-table>
        </td>
      </template>
    </data-table>

    <RemoveGroupDialog
      v-model="removeGroupDialog"
      :group="groupToRemove"
      @close="closeRemoveGroupDialog()"
      @group-removed="groupRemoved()"
    >
    </RemoveGroupDialog>

    <EditGroupDialog
      v-model="editGroupDialog"
      :group="groupToEdit"
      @close="closeEditGroupDialog()"
      @group-edited="groupEdited()"
    >

    </EditGroupDialog>
  </div>
</template>

<script>

import RemoveGroupDialog from '@/views/Companies/Market/dialogs/RemoveGroupDialog'
import EditGroupDialog from '@/views/Companies/Market/dialogs/EditGroupDialog'
export default {
  name: 'GroupsTable',
  components: { EditGroupDialog, RemoveGroupDialog },
  data () {
    return {
      removeGroupDialog: false,
      groupToRemove: {},
      editGroupDialog: false,
      groupToEdit: {},
      searchInputValue: '',
      headers: [
        {
          text: this.$t('companies.groups.groupName'),
          value: 'name'
        },
        {
          text: this.$t('companies.groups.companiesCount'),
          value: 'companiesCount'
        },
        {
          text: this.$t('companies.groups.description'),
          value: 'description'
        },
        {
          text: this.$t('companies.groups.createdBy'),
          value: 'createdBy'
        },
        {
          text: this.$t('companies.groups.dateOfCreation'),
          value: 'dateOfCreation'
        },
        {
          text: this.$t('common.actions'),
          align: 'end',
          width: '75',
          value: 'actions',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('companies.detailsHeaders.displayId'),
          value: 'displayId'
        },
        {
          text: this.$t('companies.detailsHeaders.name'),
          value: 'name'
        }
      ]
    }
  },
  methods: {
    async updateData () {
      await this.$refs.table.getData()
    },
    async groupRemoved () {
      await this.$refs.table.getData()
      this.removeGroupDialog = false
    },
    openRemoveGroupDialog (groupToRemove) {
      this.groupToRemove = groupToRemove
      this.removeGroupDialog = true
    },
    closeRemoveGroupDialog () {
      this.removeGroupDialog = false
    },
    openEditGroupDialog (groupToEdit) {
      this.groupToEdit = groupToEdit
      this.editGroupDialog = true
    },
    closeEditGroupDialog () {
      this.editGroupDialog = false
    },
    async groupEdited () {
      await this.$refs.table.getData()
      this.editGroupDialog = false
    },
    goToCompanyProfile (company) {
      this.$router.push({ name: 'CompanyDetailsMarket', params: { id: company.id } })
    }
  }
}
</script>
