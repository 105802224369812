<template>
  <v-dialog v-model="value" persistent width="500px">
    <v-card class="w-100 rounded-lg">

      <v-card-title style="word-break: break-word">
        <span>
          <span class="font-weight-regular">
            {{ $t("companies.groups.group") }}
          </span>
          <span class="font-weight-black">
            {{ group.name }}
          </span>
          <span class="font-weight-regular">
            {{ $t("companies.groups.willBeDeleted") }}
          </span>
        </span>
      </v-card-title>

      <v-card-text class="text-subtitle-1 font-weight-regular">
        {{ $t("companies.groups.doYouWantToContinue") }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          outlined
          class="ma-2"
          @click="close()"
        >
          {{ $t('common.no') }}
        </v-btn>
        <v-btn
          color="primary"
          class="ma-2"
          @click="remove()"
        >
          {{ $t('common.yes') }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import Service from '@/services'

export default {
  name: 'RemoveGroupDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return `${this.$t('companies.groups.group')}
              ${this.group.name}
              ${this.$t('companies.groups.willBeDeleted')}`
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async remove () {
      try {
        await Service.delete(
          '/CompanyGroup/DeleteGroup', {
            groupId: this.group.id
          }
        )

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation')
        )
      } catch (e) {} finally {
        this.$emit('group-removed')
      }
    }
  }
}
</script>
